<template>
  <span>
    {{ content }}
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-if="showCopyIcon"
          small
          color="grey"
          class="behavior_action_icon"
          @click="copyToClipboard"
          v-bind="attrs"
          v-on="on"
          >mdi-content-copy</v-icon
        >
      </template>
      Copy to clipboard
    </v-tooltip>
    <slot></slot>
  </span>
</template>
<script>
export default {
  name: "CopiableText",
  props: {
    content: {},
  },
  computed: {
    showCopyIcon() {
      return !!navigator.clipboard;
    },
  },
  methods: {
    async copyToClipboard() {
      await navigator.clipboard.writeText(this.content).catch(() => {
        this.displayErrorMessage("Unable to copy to clipboard");
      });
      this.displaySuccessMessage("Copied to clipboard");
    },
  },
};
</script>
